import React from "react"
import { graphql } from "gatsby"

import Link from "../components/link"
import Layout from "../components/layout"
import EventTitle from "../components/eventTitle"
import Byline from "../components/byline"
import SpeakerName from "../components/speakerName"
import SpeakerCard from "../components/speakerCard"
import RenderHtml from "../components/renderHtml"

import style from "./speaker.module.css"

export default ({ data }) => {
	const speaker = data.markdownRemark
	return (
		<Layout
			meta={{
				title: speaker.frontmatter.name,
				description: `${speaker.frontmatter.name} speaks at Accento Digital, an amazing Java/JavaScript/Ops online conference.`,
				keywords: `${speaker.frontmatter.name} Karlsruhe`,
				path: speaker.fields.path,
				socialImage: speaker.frontmatter.card,
			}}
		>
			<div>
				<h1>
					<SpeakerName speaker={speaker} showSocial />
				</h1>
				<SpeakerCard
					speakerSlug={speaker.frontmatter.slug}
					size="large"
					className={style.image}
				/>
				<RenderHtml withAst={speaker} />
			</div>
			{eventList(speaker)}
		</Layout>
	)
}

function eventList(speaker) {
	const events = [
		speaker.frontmatter.talks,
		speaker.frontmatter.igniteTalks,
		speaker.frontmatter.qa,
		speaker.frontmatter.panels,
	]
		.filter(events => events && events.length > 0)
		.flat()
	return (
		<div>
			<h2>Talks and other Contributions</h2>
			{events.map(event)}
		</div>
	)
}

function event(event) {
	return (
		<div key={event.frontmatter.slug}>
			<h3 className={style.eventTitle}>
				<EventTitle event={event} linkTitle />
			</h3>
			<Byline
				track={event.frontmatter.track}
				format={event.fields.collection}
				slug={event.frontmatter.slug}
			/>
			<p className={style.eventTeaser}>
				{event.frontmatter.excerpt} <Link to={event.fields.path}>More...</Link>
			</p>
		</div>
	)
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark(frontmatter: { slug: { eq: $slug } }) {
			htmlAst
			fields {
				path
			}
			frontmatter {
				name
				slug
				card
				...Social
				talks {
					...SpeakerEventPreview
				}
				igniteTalks {
					...SpeakerEventPreview
				}
				qa {
					...SpeakerEventPreview
				}
				panels {
					...SpeakerEventPreview
				}
			}
		}
	}
	fragment SpeakerEventPreview on MarkdownRemark {
		fields {
			path
			collection
		}
		frontmatter {
			title
			excerpt
			track
			slug
		}
	}
`
